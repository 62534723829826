import { useEffect, useState } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { Inter } from 'next/font/google';
import { useSetAtom } from 'jotai';
import { buildingsAtom } from '@/atoms/buildings';
import { NavbarWithFilter as Navbar } from '@/components/shared/Navbar';
import { useRouter } from 'next/router';
import Layout from '@/components/shared/Layout';
import Main from '@/components/pages/home/Main';
import WithOneTimeToken from '@/components/hoc/withOneTimeToken';
import api from '@/services/apis';

function Home() {
  const setBuildings = useSetAtom(buildingsAtom);
  const [isLoading, setIsLoading] = useState(true);

  const router = useRouter();
  const redirect = router.query?.redirect;

  useEffect(() => {
    if (redirect) {
      router.push(redirect);
    }
  }, [redirect, router]);

  useEffect(() => {
    const fetchBuildings = async () => {
      const response = await api.fetchBuildings();
      if (response?.error) {
        return;
      }
      setBuildings(response.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };
    fetchBuildings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Flex direction='column' minH='100vh'>
        <Navbar />

        <Flex flex='1' pos='relative' mt='px'>
          {isLoading && (
            <Flex
              pos='absolute'
              top='0'
              left='0'
              w='100%'
              h='100%'
              alignItems='center'
              zIndex='1040'
              justifyContent='center'
              bg='blackAlpha.800'>
              <Spinner color='white' />
            </Flex>
          )}

          <Main />
        </Flex>
      </Flex>
    </Layout>
  );
}

export default WithOneTimeToken(Home);
